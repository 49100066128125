import React from 'react';
import '../src/styles/dms/global.css'
import { BrowserRouter, Route, Routes } from "react-router-dom";
/* Auth */
import { Login } from './layouts/dms/AuthLayout/Login';
import { OTP } from './layouts/dms/AuthLayout/OTP';
import { Register } from './layouts/dms/AuthLayout/Register';
/* User */
import { UserList } from './pages/dms/User Management/Users/UserList';
import { UserAdd } from './pages/dms/User Management/Users/UserAdd';
import { UserEdit } from './pages/dms/User Management/Users/UserEdit';

const isAuthenticated = () => {
  return !!localStorage.getItem('token');
};

function App() {
 
  
  return (
    <BrowserRouter>
      <Routes>
        {/* User Management */}
        {/* User */}
        <Route path='/user' element={<UserList />} />
        <Route path='/user/add' element={<UserAdd />} />
        <Route path='/user/edit' element={<UserEdit />} />

        {/* Authentication */}
        <Route path='/' element={ isAuthenticated() ? <Login /> : <UserList />} />
        <Route path='/otp' element={<OTP />} />
        <Route path='/register' element={<Register />} />
</Routes>
    </BrowserRouter>
  );
}

export default App;
