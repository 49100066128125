import React, { useState, useEffect } from "react";
import {
    FaChartLine,
    FaMotorcycle,
    FaCrown,
    FaCaretDown,
    FaCaretRight,
    FaAppStore,
} from "react-icons/fa";

export const Sidebar = ({ isOpen }) => {
    const [activeMenu, setActiveMenu] = useState("");
    const [openMenu, setOpenMenu] = useState(localStorage.getItem("openMenu") || null);
    const [openSubMenu, setOpenSubMenu] = useState(localStorage.getItem("openSubMenu") || null);
    const [openSubSubMenu, setOpenSubSubMenu] = useState(localStorage.getItem("openSubSubMenu") || null);

    const handleMenuToggle = (menuName) => {
        const newOpenMenu = openMenu === menuName ? null : menuName;
        setOpenMenu(newOpenMenu);
        setOpenSubMenu(null); // Close any open submenus when switching menus

        // Save state in localStorage
        localStorage.setItem("openMenu", newOpenMenu);
    };

    const handleSubMenuToggle = (subMenuName) => {
        const newOpenSubMenu = openSubMenu === subMenuName ? null : subMenuName;
        setOpenSubMenu(newOpenSubMenu);

        // Save state in localStorage
        localStorage.setItem("openSubMenu", newOpenSubMenu);
    };

    const handleSubSubMenuToggle = (SubsubMenuName) => {
        const newOpenSubSubMenu = openSubSubMenu === SubsubMenuName ? null : SubsubMenuName;
        setOpenSubSubMenu(newOpenSubSubMenu);

        // Save state in localStorage
        localStorage.setItem("openSubSubMenu", newOpenSubSubMenu);
    };

    // Close menus when clicking outside
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (!e.target.closest(".dms-sidebar")) {
                setOpenMenu(null);
                setOpenSubMenu(null);
                localStorage.removeItem("openMenu");
                localStorage.removeItem("openSubMenu");
                localStorage.removeItem("openSubSubMenu");
            }
        };

        document.addEventListener("click", handleOutsideClick);
        return () => document.removeEventListener("click", handleOutsideClick);
    }, []);

    return (
        <div className={`dms-sidebar bg-color text-white ${isOpen ? "open" : "closed"}`}>
            {/* Sidebar Header */}
            <div className="p-3 d-flex align-items-center sidebar-title">
                <a href="/" className="d-flex align-items-center text-white text-decoration-none">
                    {!isOpen ? (
                        < FaMotorcycle size={30} className="text-white d-none d-md-block" />
                    ) : (
                        < FaMotorcycle size={30} className="text-white d-block d-md-none" />
                    )}
                    {isOpen && <h4 className="mb-0 ms-2">DMS</h4>}
                </a>
            </div>
            <hr />

            {/* Sidebar Links */}
            <ul className="nav flex-column">
                            {/* User */}
                            <li className="dms-nav-item">
                                <div className="d-flex align-items-center">
                                    <FaCaretRight />
                                    <div
                                        className="dms-nav-link text-white"
                                        onClick={() => handleSubMenuToggle("user")} // Fix here
                                    >
                                        Users Management
                                    </div>
                                </div>

                                {openSubMenu === "user" && (
                                    <ul className="submenu show">
                                        <li className="dms-nav-item">
                                            <div className="d-flex align-items-center">
                                                <FaCaretRight />
                                                <a href="/user" className="dms-nav-link text-white">User List</a>
                                            </div>
                                        </li>
                                    </ul>
                                )}
                            </li>
            </ul>
        </div>
    );
};
