import React, { useState, useEffect } from 'react';
import { Button, Table, InputGroup, Form, Dropdown, DropdownButton } from 'react-bootstrap';
import { FaEdit, FaTrash, FaFileExport, FaPlus, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { AdminLayout } from '../../../../layouts/dms/AdminLayout/AdminLayout';
import axios from 'axios';

export const UserList = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);  // ✅ Ensure users is always an array
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true); // ✅ Added loading state

  // Fetch users from API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`http://139.59.87.87:5000/api/users/getAllUsers`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, 
            "Content-Type": "application/json"
          }
        });
  
        console.log("API Response:", response.data); // ✅ Log the full response
  
        // Ensure response.data and response.data.users exist
        setUsers(response.data?.users ?? []); // ✅ Default to empty array if undefined
      } catch (error) {
        console.error('Error fetching users:', error);
        setUsers([]); // ✅ Prevents crash
      } finally {
        setLoading(false);
      }
    };
  
    fetchUsers();
  }, []);
  


  // Search Functionality
  const handleSearch = (e) => setSearch(e.target.value);
  const filteredUsers = Array.isArray(users) ? users.filter((user) =>
    user.name?.toLowerCase().includes(search.toLowerCase()) ||
    user.email?.toLowerCase().includes(search.toLowerCase()) ||
    user.role?.toLowerCase().includes(search.toLowerCase())
  ) : [];
  


  // Edit Action
  const handleEdit = (user) => {
    navigate('/user/edit', { state: { user } });
  };

  // Delete Action
  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this user?")) {
      return;
    }
  
    try {
      const token = localStorage.getItem('token'); 
      const response = await axios.delete(`http://139.59.87.87:5000/api/users/deleteUser/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
  
      if (response.status === 200) {
        alert("User deleted successfully");
        setUsers(users.filter((user) => user.id !== id)); 
      } else {
        alert("Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("An error occurred while deleting the user.");
    }
  };

  // Export Users
  const handleExport = async (format) => {
    try {
        const response = await axios.get(`http://139.59.87.87:5000/api/users/exportUsers?format=${format}`, {
            responseType: "blob",
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `users.${format === "excel" ? "xlsx" : "pdf"}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error("Export failed:", error);
    }
  };

  return (
    <AdminLayout>
      <div className="dms-pages-header sticky-header">
        <h3>User List</h3>
        <div className="d-flex">
          <DropdownButton variant="primary" title={<><FaFileExport /> Export</>} className="me-2">
            <Dropdown.Item onClick={() => handleExport("excel")}>
                <FaFileExcel className="icon-green" /> Export to Excel
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleExport("pdf")}>
                <FaFilePdf className="icon-red" /> Export to PDF
            </Dropdown.Item>
          </DropdownButton>
          <DropdownButton variant="primary" title={<><FaFileExport /> Import</>} className="me-2">
            <Dropdown.Item> <FaFileExcel className="icon-green" /> Import from Excel</Dropdown.Item>
            <Dropdown.Item> <FaFilePdf className="icon-red" /> Import from PDF</Dropdown.Item>
          </DropdownButton>
          <Button variant="primary" onClick={() => navigate('/user/add')}>
            <FaPlus /> Add User
          </Button>
        </div>
      </div>

      {/* Search Input */}
      <div className="d-flex justify-content-between mb-3">
        <InputGroup className="dms-custom-width">
          <Form.Control placeholder="Search users..." value={search} onChange={handleSearch} />
        </InputGroup>
      </div>

      {/* Table */}
      <div className="dms-table-container">
        {loading ? (
          <p>Loading users...</p>
        ) : (
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Sr.</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user, index) => (
                  <tr key={user.id}>
                    <td>{index + 1}</td>
                    <td>{user.name || "N/A"}</td>
                    <td>{user.mobile || "N/A"}</td>
                    <td>{user.email || "N/A"}</td>
                    <td>{user.role || "N/A"}</td>
                    <td>
                      <FaEdit title="Edit" className="icon-green me-2" onClick={() => handleEdit(user)} />
                      <FaTrash title="Delete" className="icon-red" onClick={() => handleDelete(user.id)} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">No users found.</td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </div>
    </AdminLayout>
  );
};
