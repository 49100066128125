import React, { useState } from "react";
import { Form, Button, Container, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const Register = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        mobile: "",
        email: "",
        password: "",
        mode: "Admin"
    });

    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        setErrors({ ...errors, [e.target.name]: "" });

        validateForm({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = (data) => {
        let newErrors = {};

        if (!data.name.trim()) newErrors.name = "Name is required";
        if (!data.mobile.trim()) {
            newErrors.mobile = "Mobile number is required";
        } else if (!/^\d{10}$/.test(data.mobile)) {
            newErrors.mobile = "Mobile number must be 10 digits";
        }

        if (!data.email.trim()) {
            newErrors.email = "Email is required";
        } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(data.email)) {
            newErrors.email = "Enter a valid email address";
        }

        if (!data.password.trim()) {
            newErrors.password = "Password is required";
        } else if (data.password.length < 6) {
            newErrors.password = "Password must be at least 6 characters";
        }

        setErrors(newErrors);
        setValidated(Object.keys(newErrors).length === 0);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Final validation before submission
        validateForm(formData);
        if (!validated) return;

        try {
            const response = await fetch("http://139.59.87.87:5000/api/users/register", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (!response.ok) throw new Error(data.message || "Something went wrong");

            alert("User created successfully");
            navigate("/");
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <div className="dms-auth-wrapper">
            <Container className="d-flex justify-content-center align-items-center vh-100">
                <Card className="p-4 shadow-sm" style={{ width: "400px", borderRadius: "10px" }}>
                    <Card.Body>
                        <h3 className="text-center mb-3">Register Form</h3>

                        <Form onSubmit={handleSubmit} noValidate>
                            <Form.Group className="dms-form-group">
                                <Form.Label>Name:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.name}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="dms-form-group">
                                <Form.Label>Mobile:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    isInvalid={!!errors.mobile}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="dms-form-group">
                                <Form.Label>Email:</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="dms-form-group">
                                <Form.Label>Password:</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    isInvalid={!!errors.password}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="dms-form-group">
                                <Form.Label>Mode:</Form.Label>
                                <Form.Select name="mode" value={formData.mode} onChange={handleChange}>
                                    <option value="Admin">Admin</option>
                                    <option value="User">User</option>
                                </Form.Select>
                            </Form.Group>

                            <div className="d-flex justify-content-between mt-3">
                                <Button type="submit" disabled={!validated}>Submit</Button>
                                <Button variant="secondary" onClick={() => navigate("/")}>Cancel</Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};
