import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AdminLayout } from '../../../../layouts/dms/AdminLayout/AdminLayout';

// http://139.59.87.87:5000/api/users/register
// heaer{
//   token: localStorage.getItem('token');
// }
// body {
//   "name": "Abhishek Chouhan",
//   "mobile": "8989189718",
//   "email": "abhi@gmail.com",1
//   "password": "abhi@123",
//   "role": "User"
// }

export const UserAdd = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');

  // State for form fields
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    role: '',
    password: '',
  });


  // Handle Input Change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Generate a random password
  const generatePassword = () => {
    const length = 8;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
    let password = '';
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    setFormData({ ...formData, password });
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

  // Form Validation
  if (!formData.name || !formData.email || !formData.role) {
    setError('Username, Email, and User Type are required fields.');
    return;
  }

  try {
    const token = localStorage.getItem('token'); 

    const response = await fetch('http://139.59.87.87:5000/api/users/createUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, 
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        role: formData.role,
        password: formData.password,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Something went wrong');
    }

    alert('User created successfully');
    navigate('/user'); 
  } catch (error) {
    setError(error.message);
  }
   
  };

  return (
    <AdminLayout>
      <div className='dms-container'>
        <h4>Add New User</h4>
         
        <div className='dms-form-container'>
          {error && <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            {/* Username */}
            <Form.Group className="dms-form-group" >
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {/* Email */}
            <Form.Group className="dms-form-group" >
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {/* Mobile */}
            <Form.Group className="dms-form-group" >
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="text"
                name="mobile"
                placeholder="Enter mobile number"
                value={formData.mobile}
                onChange={handleChange}
              />
            </Form.Group>

            {/* User Type */}
            <Form.Group className="dms-form-group">
              <Form.Label>Role</Form.Label>
              <Form.Select
                name="role"
                value={formData.role}
                onChange={handleChange}
                required
              >
                <option value="Select option">Select option</option>
                <option value="User">User</option>
              </Form.Select>
            </Form.Group>

            {/* Password */}
            <Form.Group className="dms-form-group">
              <Form.Label>Password</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Enter password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  className='me-3'
                />
                <Button
                  variant="success"
                  onClick={generatePassword}
                  className="flex-shrink-0"
                >
                  Generate
                </Button>
              </div>
            </Form.Group>

            {/* Buttons */}
            <div className="d-flex">
              <Button type="submit" variant="primary" className="me-2">
               Submit
              </Button>
              <Button type="cancel" onClick={() => navigate('/user')}>
                Back
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </AdminLayout>
  );
};
