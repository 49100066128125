import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { AdminLayout } from '../../../../layouts/dms/AdminLayout/AdminLayout';

export const UserEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = location.state || {};

  // Initialize state with the current user data
  const [name, setUsername] = useState(user?.name || '');
  const [email, setEmail] = useState(user?.email || '');
  const [mobile, setMobile] = useState(user?.mobile || '');
  const [role, setUserType] = useState(user?.role || '');
  const [ setError] = useState('');
  const [ setSuccess] = useState('');


  // Handle form submission (Update user)
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const token = localStorage.getItem('token'); 
  
      const apiUrl = `http://139.59.87.87:5000/api/users/updateUser/${user?.id}`;
  
      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, 
        },
        body: JSON.stringify({
          name,
          email,
          mobile,
          role,
        }),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.message || 'Failed to update user');
      }
  
      setSuccess('User updated successfully');
      setTimeout(() => navigate('/user'), 2000); 
    } catch (error) {
      setError(error.message);
    }
  };
  
  return (
    <AdminLayout>
      <div className="dms-container ">
        <h4>Edit User Details </h4>
        <div className="dms-form-container">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="dms-form-group">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="email" className="dms-form-group">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="mobile" className="dms-form-group">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter mobile number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="role" className="dms-form-group">
              <Form.Label>User Type</Form.Label>
              <Form.Select
                value={role}
                onChange={(e) => setUserType(e.target.value)}
                required
              >
              <option value="Select option">Select option</option>
              <option value="User">User</option>
              </Form.Select>
            </Form.Group>

            <Button type="submit">
              Save Changes
            </Button>
            <Button type='cancel' className="ms-2" onClick={() => navigate('/user')}>
              Cancel
            </Button>
          </Form>
        </div>
      </div>
    </AdminLayout>
  );
};
